import * as React from 'react';
import axios from "axios";
import { baseURL } from 'services/API';
import { Grid, Box } from "@mui/material";
import TicketsTable from 'components/Tables/TicketsTable';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import BasicSelect from 'components/select';
import FormDialog from 'components/modal';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { useGetRentalSchedules, useRentals } from 'services/hooks/rentals';
import TicketPopover from 'components/PopOvers/TicketPopover';
import { formatDate } from 'utilities/date';

export default function Tickets() {
    const [isSidebar, setIsSidebar] = React.useState(true);
    const [rental, setRental] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {rentalSchedules, isLoadingRentalSchedules, errorLoadingRentalSchedules} = useGetRentalSchedules()
    const {rentals, isLoadingRentals, error} = useRentals();

    const openPopper = Boolean(anchorEl);
    const popperId = openPopper ? 'simple-popover' : undefined;

    const handleSelectChange = (event) => {
        setRental(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickPopOver = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClosePopOver = () => {
        setAnchorEl(null);
      };

    return (
        <Box>
            <Navbar />
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Sidebar isSidebar={isSidebar}/>  
                </Grid>
                <Grid item xs={10}>
                    <h1>Active Schedules</h1>
                    <Grid container spacing={4}>
                        {/* <Grid item xs={4}>
                            <BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter Rental"} data={rentals}/>
                        </Grid> */}
                        <Grid item xs={4}>
                            
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Button variant="contained" onClick={handleClickOpen} endIcon={<AddIcon/>}>
                                Add Ticket
                            </Button>
                        </Grid> */}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ marginTop: 3 }}>
                            <TicketsTable rows={rentalSchedules} isLoadingRentalTickets={isLoadingRentalSchedules} handleClickPopOver={handleClickPopOver} id={popperId} formatDate={formatDate}/>
                        </Grid>
                    </Grid>
                    <TicketPopover popperId={popperId} openPopper={openPopper} anchorEl={anchorEl} handleClosePopOver={handleClosePopOver} handleClickOpen={handleClickOpen}/>
                    <FormDialog open={open} handleClose={handleClose}/>
                </Grid>
            </Grid>
        </Box>
    )
}
import * as React from 'react';
import axios from "axios";
import { baseURL } from 'services/API';
import { Grid } from "@mui/material";
import RentalsTable from "../../components/Tables/RentalsTable"
import Button from '@mui/material/Button';
import FormDialog from 'components/modal';
import BasicSelect from 'components/select';
import AddIcon from '@mui/icons-material/Add';
import { useRentals } from 'services/hooks/rentals';
import RentalsForm from 'components/Forms/RentalsForm';

const Rentals = () => {
    const {rentals, isLoadingRentals, error} = useRentals()
    const [open, setOpen] = React.useState(false);
    const [age, setAge] = React.useState('');

    const [rentalName, setRentalName] = React.useState('');
    const [rentalType, setRentalType] = React.useState('');
    const [rentalLocation, setRentalLocation] = React.useState('');

    const handleRentalNameChange = (event) => {
        setRentalName(event.target.value)
    }

    const handleChangeRentalType = (event) => {
        setRentalType(event.target.value)
    }

    const handleRentalLocationChange = (event) => {
        setRentalLocation(event.target.value)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectChange = (event) => {
        setAge(event.target.value);
    };

    const data = [
        {"id":1, "name":"My Name"},
        {"id":2, "name":"My Name 2"},
        {"id":3, "name":"My Name 3"}
    ]

    const handleRentalFormSubmit = async () => {
        try {
            await axios.post(`${baseURL}/landlords/rentals`, {
                related_landlord: 1,
                rental_name: rentalName,
                rental_type:rentalType,
                location: rentalLocation,
            });
            // setSuccess(true);
            // setLoading(false);
            setOpen(false);
        } catch (err) {
            console.log(err)
            // setLoading(false);
            // setError(true);
            // setErrcode(err.message);
        }
    }

    return (

        <Grid container spacing={2}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <BasicSelect value={age} handleChange={handleSelectChange} label={"Rentals"} data={data}/>
                </Grid>
                <Grid item xs={4}>
                    
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" onClick={handleClickOpen} endIcon={<AddIcon/>}>
                        Add Properties
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginTop: 3 }}>
                    <RentalsTable rows={rentals} isLoadingRentals={isLoadingRentals}/>
                </Grid>
            </Grid>
                <FormDialog open={open} handleClose={handleClose} text={"Add all Rentals Details"} content={<RentalsForm rentalType={rentalType} handleRentalNameChange={handleRentalNameChange} handleChangeRentalType={handleChangeRentalType} handleRentalLocationChange={handleRentalLocationChange}/>} title={"Rentals Form"} handleSubmit={handleRentalFormSubmit}/>
        </Grid>
    );
};

export default Rentals;
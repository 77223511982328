import { Box, useMediaQuery, Tab, Tabs, Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import * as React from 'react';
import PropTypes from 'prop-types';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import Rentals from "./rentals";
import Units from "./units";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Properties = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isSidebar, setIsSidebar] = React.useState(true);
  // const { _id, picturePath } = useSelector((state) => state.user);

  return (
    <Box>
      <Navbar />
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Sidebar isSidebar={isSidebar}/>  
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={2}
              width="auto"
              padding="2rem 6%"
              display={isNonMobileScreens ? "flex" : "block"}
              gap="0.5rem"
              // justifyContent="space-between"
            >
              <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs fullWidth value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Properties" {...a11yProps(0)} />
                  <Tab label="Units" {...a11yProps(1)} />
                  {/* <Tab label="Purchases" {...a11yProps(0)} />
                  <Tab label="Purchase Orders" {...a11yProps(0)} /> */}
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                
                <TabPanel value={value} index={0}>
                  <Rentals/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Units/>
                </TabPanel>
          
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Box>
  );
};

export default Properties;
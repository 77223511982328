import * as React from 'react';
import { Grid, Box } from "@mui/material";
import PaymentsTable from 'components/Tables/PaymentsTable';
import BasicSelect from 'components/select';
import FormDialog from 'components/modal';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { useGetPaymentsData } from 'services/hooks/rentals';

export default function Payments() {
    const [isSidebar, setIsSidebar] = React.useState(true);
    const [rental, setRental] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const {paymentsData, isLoadingPaymentsData, errorLoadingPaymentsData} = useGetPaymentsData()

    const handleSelectChange = (event) => {
        setRental(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const data = [
        {"id":1, "name":"My Name"},
        {"id":2, "name":"My Name 2"},
        {"id":3, "name":"My Name 3"}
    ]

    return (
        <Box>
            <Navbar />
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Sidebar isSidebar={isSidebar}/>  
                </Grid>
                <Grid item xs={10}>
                    <h1>Payments</h1>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Rental"} data={data}/>
                        </Grid>
                        {/* <Grid item xs={4}>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" onClick={handleClickOpen} endIcon={<AddIcon/>}>
                                Add Rentals
                            </Button>
                        </Grid> */}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ marginTop: 3 }}>
                            <PaymentsTable rows={paymentsData}/>
                        </Grid>
                    </Grid>
                    <FormDialog open={open} handleClose={handleClose} />
                </Grid>
            </Grid>
        </Box>
    )
}
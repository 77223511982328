import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import BasicSelect from 'components/select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function TenantsForm({handleChangeTenantNumber, rentals, handleChangeRental, isLoadedUnits, handleChangeRentalUnit, units, unit, errorTenant, tenant, rental, loadedTenant}) {
  return (
    <Grid container
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
    <Grid item xs={12} spacing={2} sx={{marginTop: 2}}>
        <TextField
            required
            id="outlined-required"
            label="Tenants Number"
            onChange={handleChangeTenantNumber}
            fullWidth={true}
            variant="standard"
            margin="dense"
            autoFocus
        />
      </Grid>

      {loadedTenant ? (
        <Grid item xs={12} spacing={2} sx={{marginTop: 2}}>
        <Card variant="outlined" sx={{ minWidth: 100 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Tenant Name: {tenant.first_name} {tenant.last_name}
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Tenant Contact: +256{tenant.phone_number}
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Tenant Number: {tenant.tenant_number}
              </Typography>
            </CardContent>        
          </Card>
      </Grid>
      ) : (
        <></>
      )}
      

      <Grid item xs={12} spacing={2} sx={{marginTop: 2}}>
        <FormControl fullWidth sx={{marginTop: 2}}>
          <InputLabel id="demo-simple-select-label">Select Rental</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="rental-select"
                value={rental}
                label="Select Rental"
                onChange={handleChangeRental}
              >
                {rentals.map((value, index ) => {
                    return (
                        <MenuItem key={index} value={value.id}>{value.rental_name} - {value.location}</MenuItem>
                    )
                })}
              </Select>
        </FormControl>

        {isLoadedUnits ? (
          <FormControl fullWidth sx={{marginTop: 2}}>
              <InputLabel id="demo-simple-select-label">Select Unit</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="unit-select"
                value={unit}
                label="Select Unit"
                onChange={handleChangeRentalUnit}
              >
                {units.map((value, index ) => {
                    return (
                        <MenuItem key={index} value={value.id}>{value.unit_name} - {value.unit_type}</MenuItem>
                    )
                })}
              </Select>
          </FormControl>
        ): (
          <></>
        )}
         
      </Grid>
    </Grid>
  );
}
import * as React from 'react';
import { Grid, Box, Paper } from "@mui/material";
import PaymentsTable from 'components/Tables/PaymentsTable';
import TicketsTable from 'components/Tables/TicketsTable';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import BasicCard from 'components/Card';
import DoughnutChart from 'components/Graphs/DoughnutChart';
import BarChart from 'components/Graphs/BarChart';
import { useGetDashboardData } from 'services/hooks/rentals';
import Skeleton from '@mui/material/Skeleton';
import { formatDate } from 'utilities/date';

export default function Dashboard() {
    const [isSidebar, setIsSidebar] = React.useState(true);
    const [rental, setRental] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const {dashboardData, isLoadingDashboardData, errorLoadingDashboardData} = useGetDashboardData()

    return (
        <Box>
            <Navbar />
            
            {isLoadingDashboardData ? (
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Sidebar isSidebar={isSidebar}/>  
                </Grid>
                <Grid item xs={10}>
                    <Grid container spacing={4}>
                        <h1>Admin Dashboard</h1>
                        <Grid item xs={3}>
                            <Skeleton variant="rectangular" width={60} height={60} />
                        </Grid>
                        <Grid item xs={3}>
                            <Skeleton variant="rectangular" width={60} height={60} />
                        </Grid>
                        <Grid item xs={3}>
                            <Skeleton variant="rectangular" width={60} height={60} />
                        </Grid>
                        <Grid item xs={3}>
                            <Skeleton variant="rectangular" width={60} height={60} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            ) : (
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Sidebar isSidebar={isSidebar}/>  
                </Grid>
                <Grid item xs={10}>
                    <h1>Admin Dashboard</h1>
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <BasicCard name={"LANDLORD PROPERTIES"} value={dashboardData.properties}/>
                        </Grid>
                        <Grid item xs={3}>
                            <BasicCard name={"TOTAL COLLECTED (UGX)"} value={new Intl.NumberFormat('en-US').format(dashboardData.total_collected)}/>
                        </Grid>
                        <Grid item xs={3}>
                            <BasicCard name={"ACTIVE SCHEDULES"} value={dashboardData.active_schedules}/>
                        </Grid>
                        <Grid item xs={3}>
                            <BasicCard name={"ACTIVE USERS"} value={dashboardData.active_users}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid container item xs={7} spacing={2}
                            sx={{
                                marginTop: 4,
                                padding: 4,
                            }}
                        >
                            <Paper sx={{
                                margin: 3,
                                padding: 4,
                                width: "100%"
                            }}>
                                <h2>Rent Payments Per Month</h2>                                
                                <BarChart/>
                            </Paper>   
                        </Grid>
                        <Grid container item xs={5} spacing={2}
                            sx={{
                                marginTop: 4,
                                padding: 4,
                            }}
                        >   
                            <Paper sx={{
                                margin: 3,
                                padding: 4,
                                width: "100%"
                            }}>
                                <h2>Rent Payments</h2>
                                <DoughnutChart/>
                            </Paper>                               
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
             )}
        </Box>
    )
}
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from "@mui/material";

export default function UnitsForm(props) {
  return (
    <Grid container
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
    <Grid item xs={12} spacing={2}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Rental</InputLabel>
            <Select
            labelId="rental-id"
            id="rental-id"
            value={props.rental}
            label="Properties"
            onChange={props.handleChangeRental}
            >
              {props.rentals.map((value, index) => {
                return (
                  <MenuItem key={index} value={value.id}>{value.rental_name}</MenuItem>
                )
              })}
            </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} spacing={2}>
        <TextField
            required
            id="outlined-required"
            label="Unit Name"
            onChange={props.handleChangeUnitName}
            fullWidth={true}
        />

        {/* <TextField
            required
            id="outlined-required"
            label="Unit Size"
            onChange={props.handleChangeUnitSize}
            fullWidth={true}
        /> */}
      </Grid>
        
      <Grid container spacing={2}>
        <Grid item xs={6} spacing={2}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Rent Currency</InputLabel>
                <Select
                labelId="rental-type"
                id="rental-type-id"
                value={props.rentalType}
                label="Age"
                onChange={props.handleChangeRentalCurrency}
                >
                    <MenuItem value="UGX">UGX</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="KES">KES</MenuItem>
                </Select>
            </FormControl>
        </Grid>

        <Grid item xs={6} spacing={2}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Unit Type</InputLabel>
                <Select
                labelId="rental-type"
                id="rental-type-id"
                value={props.rentalType}
                label="Age"
                onChange={props.handleChangeRentalType}
                >
                    <MenuItem value="REGULAR">REGULAR</MenuItem>
                    <MenuItem value="CONDOMINIUM">CONDOMINIUM</MenuItem>
                    <MenuItem value="SEMI-DETACHED">SEMI DETACHED</MenuItem>
                </Select>
            </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} spacing={2}>
            <TextField
            required
            id="outlined-required"
            label="Unit Rent"
            onChange={props.handleChangeUnitRent}
            />
        </Grid>
      <Grid item xs={6} spacing={2}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Rent Cycle</InputLabel>
            <Select
            labelId="rental-type"
            id="rental-type-id"
            value={props.rentCycle}
            label="Age"
            onChange={props.handleChangeRentCycle}
            >
                <MenuItem value="DAILY">DAILY</MenuItem>
                <MenuItem value="WEEKLY">WEEKLY</MenuItem>
                <MenuItem value="MONTHLY">MONTHLY</MenuItem>
                <MenuItem value="QUARTERLY">QUARTERLY</MenuItem>
            </Select>
        </FormControl>
       </Grid>
      </Grid>

      {/* <Grid container spacing={2}>
        <Grid item xs={6} spacing={2}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Has Down Payment</InputLabel>
                <Select
                labelId="rental-type"
                id="rental-type-id"
                value={props.rentIsDownPayment}
                label="Age"
                onChange={props.handleChangeIsRentDownPayment}
                >
                    <MenuItem value="True">YES</MenuItem>
                    <MenuItem value="False">NO</MenuItem>   
                </Select>
            </FormControl>
        </Grid>

        <Grid item xs={6} spacing={2}>
            <TextField
            required
            id="outlined-required"
            label="Down Payment Amount"
            onChange={props.handleChangeDownPayment}
            />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} spacing={2}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Has Security Payment</InputLabel>
                <Select
                labelId="rental-type"
                id="rental-type-id"
                value={props.rentIsSecurityPayment}
                label="Age"
                onChange={props.handleChangeIsRentSecurityPayment}
                >
                    <MenuItem value="True">YES</MenuItem>
                    <MenuItem value="False">NO</MenuItem>   
                </Select>
            </FormControl>
        </Grid>

        <Grid item xs={6} spacing={2}>
            <TextField
            required
            id="outlined-required"
            label="Security Payment Amount"
            onChange={props.handleChangeSecurityPayment}
            />
        </Grid> */}
        {/* </Grid> */}
    </Grid>
  );
}
import * as React from 'react';
import axios from "axios";
import { useSelector} from "react-redux";
import { baseURL } from 'services/API';
import { Grid, Box } from "@mui/material";
import TenantsTable from 'components/Tables/TenantsTable';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import BasicSelect from 'components/select';
import FormDialog from 'components/modal';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import TenantsForm from 'components/Forms/TenantsForm';
import { useGetRentalTenants, useRentals } from 'services/hooks/rentals';
import TopSnack from 'components/TopSnack';
import { formatDate } from 'utilities/date';

export default function Tenants() {
    const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const [isSidebar, setIsSidebar] = React.useState(true);
    const [rental, setRental] = React.useState('')
    const [unit, setUnit] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const {rentalTenants, isLoadingRentalTenants, errorLoadingRentalTenants} = useGetRentalTenants()
    const {rentals, isLoadingRentals, error} = useRentals()
    const [isLoadedUnits, setIsLoadedUnits] = React.useState(false);
    const [units, setUnits] = React.useState([])
    const [tenantDetails, setTenantDetails] = React.useState({});
    const [loadedTenant, setLoadedTenant] = React.useState(false);
    const [isLoadingTenantDetails, setLoadingTenantDetails] = React.useState(true);
    const [errorLoadingTenantDetails, setErrorLoadingTenantDetails] = React.useState(false);

    const handleSelectChange = async (event) => {
        setRental(event.target.value);
        try {
            const response = await axios.get(`${baseURL}/landlords/rentals/units/unoccupied?rental_id=${event.target.value}`);
            setUnits(response.data.data);
            setIsLoadedUnits(true)
            return true
        } catch (err) {
            return false
        }
    };

    const handleChangeRentalUnit = (event) => {
        setUnit(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenSnack(false);
    };

    const handleChangeTenantNumber = async (e) => {
        setLoadedTenant(false)
        const tenant_number = e.target.value
        if(tenant_number.length === 8){
            try {
                const response = await axios.get(`${baseURL}/tenants?tenant_number=${tenant_number}`);
                setTenantDetails(response.data.data);
                setLoadedTenant(true)
                setLoadingTenantDetails(false);
                return true
            } catch (err) {
                console.log(err)
                setLoadingTenantDetails(false);
                setErrorLoadingTenantDetails(true);
                return false
            }
        }
    }

    const handleSubmitTenant = async () => {
        try {
            await axios.post(`${baseURL}/landlords/rentals/units/assign`, {
                unit_id: unit,
                tenant_number: tenantDetails.tenant_number,
            });
            setOpen(false);
            setOpenSnack(true);
            setMessage("Tenant Added Successfully")
        } catch (err) {
            console.log(err)
            
        }
    }


    return (
        <Box>
            <Navbar />
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Sidebar isSidebar={isSidebar}/>  
                </Grid>
                <Grid item xs={10}>
                    <h1>Users</h1>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Referral Code"} data={rentals} isLoading={isLoadingRentals} id={"main-tenants-rental-select"}/>
                        </Grid>
                        <Grid item xs={4}>
                            
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Button variant="contained" onClick={handleClickOpen} endIcon={<AddIcon/>}>
                                Add Tenant
                            </Button>
                        </Grid> */}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ marginTop: 3 }}>
                            <TenantsTable rows={rentalTenants} isLoadingRentalTenants={isLoadingRentalTenants}/>
                        </Grid>
                    </Grid>
                    <FormDialog open={open} handleClose={handleClose} title={"Add New Tenant"} handleSubmit={handleSubmitTenant} content={<TenantsForm handleChangeTenantNumber={handleChangeTenantNumber} rentals={rentals} tenant={tenantDetails} errorTenant={errorLoadingTenantDetails} loadedTenant={loadedTenant} unit={unit} units={units} isLoadedUnits={isLoadedUnits} handleChangeRental={handleSelectChange} handleChangeRentalUnit={handleChangeRentalUnit}/>}/>
                    <TopSnack open={openSnack} handleCloseSnack={handleCloseSnack} message={message}/>
                </Grid>
            </Grid>
        </Box>
    )
}